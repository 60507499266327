import {
  Box,
  Flex,
  HStack,
  Text
} from '@chakra-ui/react';
import { SnsLinks } from '../SnsLinks/SnsLinks';
import { PcFooterLink } from './PcFooterLink';
import { Image } from '@/components/Image';
import {
  getPageInfo,
  PageName
} from '@/config/pages';

const menus: PageName[] = [
  'studio',
  'guide',
  'brand',
  'brand/reraku',
  'news',
  'faq',
  'education',
  'card',
  'blog',
  'recruit',
  'contact',
  'privacy_policy',
  'operating_company',
  'tokushoho',
  'about_rerakupay',
];

export type PcFooterItemsProps = {};

export const PcFooterHeight = 380;

export const PcFooterItems = ({ }: PcFooterItemsProps) => {
  return (
    <Box
      h={`${PcFooterHeight}px`}
      bg={'brand.500'}
      color={'white'}
      px={8}
      py={4}
    >
      <Box
        maxW={'1280px'}
        h={'full'}
        mx={'auto'}
        position={'relative'}
      >
        <HStack
          alignItems={'baseline'}
          pb={4}
          justifyContent={'space-evenly'}
        >
          <Box
            mt={5}
          >
            <Image
              src={'/images/logo/footer_logo.png'}
              alt={'rerakuロゴ ホワイト'}
              width={250}
              height={45}
            />
            <Flex
              flexWrap={'wrap'}
              pt={4}
            >
              {menus.map((menu) => (
                <PcFooterLink
                  key={menu}
                  pageLink={getPageInfo(menu, {})}
                />
              ))}
            </Flex>
          </Box>
        </HStack>

        <Box
          pt={3}
          w={'full'}
          borderTop={'1px solid'}
          borderColor={'white'}
          position={'absolute'}
          bottom={0}
        >
          <HStack
            mt={1}
            justifyContent={'space-between'}
          >
            <SnsLinks/>
            <Box>
              <Text>&copy; MEDIROM Wellness Co. All Right Reserved.</Text>
            </Box>
          </HStack>
        </Box>
      </Box>
    </Box>
  );
};
