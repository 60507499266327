import {
  Box,
  Flex,
  Text
} from '@chakra-ui/react';
import { SnsLinks } from '../SnsLinks/SnsLinks';
import { SpFooterLink } from './SpFooterLink';
import { Image } from '@/components/Image';
import {
  getPageInfo,
  PageName
} from '@/config/pages';

export type SpFooterItemsProps = {};

const menus: PageName[] = [
  'studio',
  'news',
  'faq',
  'recruit',
  'contact',
  'operating_company',
  'privacy_policy',
  'tokushoho',
  'guide',
  'education',
  'brand',
  'brand/reraku',
  'card',
  'blog',
  'about_rerakupay',
];

export const SpFooterHeight = 500;

export const SpFooterItems = ({ }: SpFooterItemsProps) => {
  return (
    <Box
      minH={`${SpFooterHeight}px`}
      bg={'brand.500'}
      color={'white'}
      position={'relative'}
    >
      <Flex
        flexDirection={'column'}
      >
        <Flex
          flexWrap={'wrap'}
          mx={6}
          my={10}
        >
          {menus.map((menu) => (
            <SpFooterLink
              key={menu}
              pageLink={getPageInfo(menu, {})}
            />
          ))}
        </Flex>
      </Flex>
      <Box
        w={'full'}
        textAlign={'center'}
        position={'absolute'}
        bottom={3}
      >
        <Box
          width={'fit-content'}
          mx={'auto'}
          mb={4}
        >
          <Image
            src={'/images/logo/footer_logo.png'}
            alt={'rerakuロゴ ホワイト'}
            width={250}
            height={45}
          />
        </Box>
        <Text>&copy; MEDIROM Wellness Co. All Right Reserved.</Text>
        <Box
          pt={5}
        >
          <SnsLinks/>
        </Box>
      </Box>
    </Box>
  );
};
